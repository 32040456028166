.App {
  padding-bottom: 65px;
  float: left;
  width: 100%;
  overflow: visible !important;
  background-color: rgb(247, 248, 250);
  height: 100%;
  
}

.center-1 {
  text-align: left;
  max-width: 1024px;
  margin: 24px auto;
  background-color: white;
  border: 0 solid #ffffff;
  box-shadow: 2px 4px 14px rgba(0, 0, 0, 0.25);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

p {
  margin-bottom: 0px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.person {
  display: inline-block;
  border: 2px solid #eee;
  box-shadow: 0 2px 2px #ccc;
  margin: 20px;
  width: 200px;
}

.login {
  /* display: inline-block; */
  /* border: 2px solid #eee; */
  box-shadow: 2px 2px 2px 2px slategray;
  margin: 20px;
  width: auto;
  text-align: left;
  padding: 5px;
  background-color: steelblue;
  color: white;
}

.btn-right {
  margin-top: 5px;
}
.container-edu {
  margin-top: 18px;
  display: grid;
  grid-template-columns: 1fr 240px;
}

.cnn-0 {
  border-bottom: 1px solid black;
  font-weight: bold;
}

.container-work {
  padding: 16px;
  text-align: left;
  display: grid;
  grid-template-columns: 1fr;
}

.cn-0 {
  margin: 20px;
  text-align: left;
}

.cn-1 {
  margin: 20px;
  text-align: left;
}

.cn-2 {
  padding: 20px;
  background-color: orangered;
  color: white;
}

.cn-3 {
  margin: 20px;
  text-align: left;
}

.ul-1 {
  list-style-type: none;
  margin-top: 20px;
}
.div-right {
  text-align: right;
  margin: 5px;
}

.navbar {
  background-color: steelblue;
  color: white;
  font-weight: bolder;
}

.menu-size {
  height: 30px;
}

.settings-size {
  height: 30px;
}

.user {
  text-align: left;
  background-color: white;
  font-weight: bolder;
  width: 100%;
  padding: 20px 10px;
  display: grid;
  grid-template-columns: 1fr;
}
.user-img {
  width: 200px;
}

/* .hello{
  display: inline-block;
  width: 78%;
  vertical-align: top;
  margin-left: 10px;
} */

.img-loc {
  display: grid;
  grid-template-columns: 1fr;
}
.text {
  margin: 20px;

  display: grid;
  grid-template-columns: 1fr;
}

.outer-div {
  text-align: left;
  display: grid;
  background-color: rgb(69, 78, 92);
  color: white;
}

.sm-ic {
  width: 100%;
  text-align: left;
  display: grid;
  grid-template-columns: 40px 365px 40px 365px;
}

.center-0 {
  text-align: left;
  margin: 8px;
}
.img-sm {
  /* width: 180px; */
  display: inline-block;
  width: 30px;
  margin: 5px 0;
}

.skills{
  margin-top: 10px;
  font-weight: bold;
}

.progress3 {

  border-radius: 30px;
  background: rgba(0, 0, 0, 0.09);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar3 {
  height: 18px;
  border-radius: 30px;
  background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;  
  transition-property: width, background-color;    
}

.progress-moved .progress-bar3 {
  width: 60%; 
  background-color: rgb(69, 78, 92);  
  animation: progressAnimation3 6s;
}

@keyframes progressAnimation3 {
  0%   { width: 5%; background-color: grey;}
  100% { width: 60%; background-color: rgb(69, 78, 92); }
}

.progress1 {

  border-radius: 30px;
  background: rgba(0, 0, 0, 0.09);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar1 {
  height: 18px;
  border-radius: 30px;
  background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;  
  transition-property: width, background-color;    
}

.progress-moved .progress-bar1 {
  width: 90%; 
  background-color: rgb(69, 78, 92);  
  animation: progressAnimation1 6s;
}

@keyframes progressAnimation1 {
  0%   { width: 5%; background-color: grey;}
  100% { width: 90%; background-color: rgb(69, 78, 92); }
}

.progress2 {

  border-radius: 30px;
  background: rgba(0, 0, 0, 0.09);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar2 {
  height: 18px;
  border-radius: 30px;
  background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;  
  transition-property: width, background-color;    
}

.progress-moved .progress-bar2 {
  width: 80%; 
  background-color: rgb(69, 78, 92);  
  animation: progressAnimation2 6s;
}

@keyframes progressAnimation2 {
  0%   { width: 5%; background-color: grey;}
  100% { width: 80%; background-color: rgb(69, 78, 92); }
}

.progress-1{
  color: #282c34;
}
.img-sm-p {
  display: inline-block;
  width: 30px;
  margin: 5px 0;
}

.img-sm-e {
  display: inline-block;
  width: 30px;
  margin: 5px 0;
}

.sm-ic-git {
  display: inline-block;

  text-align: center;

}
.sm-ic-link {
  display: inline-block;
  text-align: center;

  
  vertical-align: middle;
}
.sm-ic-apple {
  display: inline-block;
  text-align: center;

  background: #aaaaaa;
}
.sm-ic-whats {
  display: inline-block;
  text-align: center;

  background: #25d366;
}

.outter-sm {
  
}

.bor {
  border-bottom: 1px solid black;
  font-weight: bold;
}
.bor-left {
  border-left: 1px solid black;
  margin: 20px;
  position: relative;
}

.dot {
  position: absolute;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  border: 3px solid rgb(69, 78, 92);
  top: -9px;
  left: -5px;
  background-color: white;
}

.inner-txt {
  margin: 0 5px;
  font-weight: bold;
  position: relative;
  top: -16px;
  left: 9px;
}

.dot-1{
  background-color: white;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  border: 3px solid rgb(69, 78, 92);
  top: 8px;
  left: -5px;
  position: absolute;
}

.pos{
  position: relative;
}

.inner-txt-1{
  margin: 0 14px;
  font-weight: bold;
  
  top: -16px;
  left: 9px;
}

.dot-2{
  background-color: white;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  border: 3px solid rgb(69, 78, 92);
  top: 8px;
  left: -5px;
  position: absolute;
}

.pos-1{
  position: relative;
}

.inner-txt-2{
  margin: 0 14px;
  font-weight: bold;
  
  top: -16px;
  left: 9px;
}
@media only screen and (max-width: 768px) {
  .outer-div {
    grid-template-columns: 1fr;
  }
  .img-loc {
    margin: 20px auto;
  }
  .sm-ic {
    grid-template-columns: 40px 1fr;
    margin: 15px 0px;
  }
  .container-edu {
    grid-template-columns: 1fr;
  }
  .container-work {
    grid-template-columns: 1fr;
  }
  .text {
    grid-template-columns: 1fr;
  }
  .outter-sm {
    margin: 0px;
  }
  .sm-ic-link {
    padding: 10px 0px;
  }
  .user-img{
  width: 312px;
    border-radius: 115px;
  }
}

@media only screen and (min-width: 900px) {
  .outer-div {
    grid-template-columns: 1fr 5fr;
  }
  .img-loc {
    margin: 0 auto;
  }
}



.progress4 {

  border-radius: 30px;
  background: rgba(0, 0, 0, 0.09);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar4 {
  height: 18px;
  border-radius: 30px;
  background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;  
  transition-property: width, background-color;    
}

.progress-moved .progress-bar4 {
  width: 80%; 
  background-color: rgb(69, 78, 92);  
  animation: progressAnimation4 6s;
}

@keyframes progressAnimation4 {
  0%   { width: 5%; background-color: grey;}
  100% { width: 80%; background-color: rgb(69, 78, 92); }
}


.progress5 {

  border-radius: 30px;
  background: rgba(0, 0, 0, 0.09);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar5 {
  height: 18px;
  border-radius: 30px;
  background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;  
  transition-property: width, background-color;    
}

.progress-moved .progress-bar5 {
  width: 80%; 
  background-color: rgb(69, 78, 92);  
  animation: progressAnimation5 6s;
}

@keyframes progressAnimation5 {
  0%   { width: 5%; background-color: grey;}
  100% { width: 80%; background-color: rgb(69, 78, 92); }
}

.progress6 {

  border-radius: 30px;
  background: rgba(0, 0, 0, 0.09);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar6 {
  height: 18px;
  border-radius: 30px;
  background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;  
  transition-property: width, background-color;    
}

.progress-moved .progress-bar6 {
  width: 80%; 
  background-color: rgb(69, 78, 92);  
  animation: progressAnimation6 6s;
}

@keyframes progressAnimation6 {
  0%   { width: 5%; background-color: grey;}
  100% { width: 80%; background-color: rgb(69, 78, 92); }
}

.progress7 {

  border-radius: 30px;
  background: rgba(0, 0, 0, 0.09);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar7 {
  height: 18px;
  border-radius: 30px;
  background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;  
  transition-property: width, background-color;    
}

.progress-moved .progress-bar7 {
  width: 80%; 
  background-color: rgb(69, 78, 92);  
  animation: progressAnimation7 6s;
}

@keyframes progressAnimation7 {
  0%   { width: 5%; background-color: grey;}
  100% { width: 80%; background-color: rgb(69, 78, 92); }
}


.progress8 {

  border-radius: 30px;
  background: rgba(0, 0, 0, 0.09);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px rgba(255, 255, 255, 0.08);
}

.progress-bar8 {
  height: 18px;
  border-radius: 30px;
  background-image: 
    linear-gradient(to bottom, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.05));
  transition: 0.4s linear;  
  transition-property: width, background-color;    
}

.progress-moved .progress-bar8 {
  width: 80%; 
  background-color: rgb(69, 78, 92);  
  animation: progressAnimation8 6s;
}

@keyframes progressAnimation8 {
  0%   { width: 5%; background-color: grey;}
  100% { width: 80%; background-color: rgb(69, 78, 92); }
}

.blink{
  animation:blinkingText 1s infinite;
  font-family: 'Press Start 2P', cursive;
  text-align: center;
  justify-content: center;
  font-size: 26px;
}
@keyframes blinkingText{
  0%{     color: #000;    }
  49%{    color: #000; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: #000;    }
}

/* unvisited link */
a:link {
  color:white !important;
   text-decoration:none !important;
}

/* visited link */
a:visited {
  color: white !important;
   text-decoration:none !important;
}

/* mouse over link */
a:hover {
  color:  white !important;
   text-decoration:none !important;
}

/* selected link */
a:active {
  color: white !important;
   text-decoration:none !important;
}